import { Col, Form, Input, Modal, Row, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { upsertKhachHangNCC } from "store/slices/khachhangSlice";

const ModalKhachHangNhaCungCap = ({
  loaiDoiTuong,
  open,
  setOpen,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.khachhang);
  const dispatch = useDispatch();

  const LoaiDoiTuong = [
    {
      label: "Khách hàng",
      value: 1,
      disabled: loaiDoiTuong === 2,
    },
    {
      label: "Nhà cung cấp",
      value: 2,
      disabled: loaiDoiTuong === 1,
    },
    {
      label: "Khách hàng và Nhà cung cấp",
      value: 4,
      disabled: false,
    },
  ];

  const onFinish = (values) => {
    const payload = {
      ...values,
      onSuccess: (value) => {
        if (onSuccess) onSuccess(value?.data);
        form.resetFields();
      },
    };
    dispatch(upsertKhachHangNCC(payload));
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={() => handleCancel()}
        okText="Lưu"
        onOk={() => handleOk()}
        okButtonProps={{ disabled: loading }}
      >
        <Form form={form} layout="vertical" onFinish={onFinish} className="p-3">
          <Row gutter={16}>
            <Col sm={24} md={24} lg={24}>
              <Form.Item
                name="tenCongTy"
                label="Tên công ty"
                rules={[
                  {
                    required: true,
                    message: "Tên công ty",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <Form.Item name="maSoThue" label="Mã số thuế">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <Form.Item
                name="loaiKhachHang"
                label="Loại đối tượng"
                rules={[
                  {
                    required: true,
                    message: "Loại đối tượng",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  options={LoaiDoiTuong || []}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={24} lg={24}>
              <Form.Item
                name="diaChi"
                label="Địa chỉ"
                rules={[
                  {
                    required: true,
                    message: "Địa chỉ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item name="dienThoai" label="Điện thoại">
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item name="nguoiLienHe" label="Người liên hệ">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalKhachHangNhaCungCap;
